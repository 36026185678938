<template>
  <section class="">
    <div
      class="csn-notification-container"
      :class="{ 'csn-notification-container-open': isDescriptionOpen }"
    >
      <img :src="gameImage" class="csn-notification-image" />
      <div class="csn-notification-right-container">
        <div class="csn-notification-data">
          <p
            v-if="name"
            class="csn-notification-data-item csn-notification-bonus"
          >
            <span class="csn-notification-label">{{ t('bonus') }}</span>
            <span>{{ name }}</span>
          </p>
          <p v-if="amount" class="csn-notification-data-item">
            <span class="csn-notification-label">{{ t('amount') }}</span>
            <span>{{ amount }}</span>
          </p>
          <p v-if="freeSpin" class="csn-notification-data-item">
            <span class="csn-notification-label">{{ t('free_spin') }}</span>
            <span>{{ freeSpin }}</span>
          </p>
          <p v-if="percent" class="csn-notification-data-item">
            <span class="csn-notification-label">{{ t('percent') }}</span>
            <span>{{ percent }}%</span>
          </p>
          <p v-if="bonusCode" class="csn-notification-data-item">
            <span class="csn-notification-label">{{ t('code') }}</span>
            <span>{{ bonusCode }}</span>
          </p>
        </div>
        <div class="csn-notification-button-container">
          <Route
            :to="{
              name: routeDeposit,
              params: { bonusCode },
            }"
          >
            <button
              v-if="bonusCode"
              @click="$emit('toggle')"
              class="btn casino-btn csn-notification-redeem-btn"
            >
              {{ t('use') }}
            </button>
          </Route>
        </div>
      </div>
    </div>
    <div v-if="isDescriptionOpen" class="csn-notification-desciption">
      {{ description }}
    </div>
  </section>
</template>
<script>
import Route from '@/components/Route'
import { USER_NOTIFICATION, RouteName } from '@/constants'

export default {
  name: USER_NOTIFICATION,
  components: {
    Route,
  },
  data: () => ({
    isDescriptionOpen: false,
  }),
  props: {
    gameImage: String,
    name: String,
    amount: String,
    bonusCode: String,
    description: String,
    freeSpin: [String, Number],
    percent: [String, Number],
  },
  computed: {
    t() {
      return this.$createComponentTranslator(USER_NOTIFICATION)
    },
    routeDeposit: () => RouteName.DEPOSIT,
  },
}
</script>
